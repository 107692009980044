import Transformer from './Transformer'
import ContractTypeMonteTransformer from '@/assets/js/dexie/transformers/ContractTypeMonteTransformer.js'
import ContractConfigTransformer from '@/assets/js/dexie/transformers/ContractConfigTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractConfigTypeMonteTransformer extends Transformer {

    table = 'contract_config_type_monte'
    transactionTables = [
        'contract_config_type_monte_pays',
    ]

    async pays(item) {
        return this.fetchRelationship(item, 'contract_config_type_monte_pays')
    }

	async fetch (col) {
        this.additionalColumns({
            'pays': this.pays,
        })

	    return col.with({
	        'contract_type_monte'                     : 'contractconfigtypemonte_typemonte',
            'contract_config'                         : 'contractconfigtypemonte_contractconfig'
	    })
	}

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transform (contract_config_typemonte) {
        return {
            'contractconfigtypemonte_id'					: contract_config_typemonte.contractconfigtypemonte_id,
            'contractconfigtypemonte_label'					: contract_config_typemonte.contractconfigtypemonte_label,
            'contractconfigtypemonte_comment'				: contract_config_typemonte.contractconfigtypemonte_comment,
            'contractconfigtypemonte_contractconfig'        : contract_config_typemonte.contractconfigtypemonte_contractconfig,
            'contractconfigtypemonte_available_ca'          : contract_config_typemonte.contractconfigtypemonte_available_ca,
            'contractconfigtypemonte_paillette'             : contract_config_typemonte.contractconfigtypemonte_paillette,
            'contractconfigtypemonte_archive'        	    : contract_config_typemonte.contractconfigtypemonte_archive,
            'contract_type_monte'							: await ContractTypeMonteTransformer.process(contract_config_typemonte.contract_type_monte),
            'contract_config'                               : await ContractConfigTransformer.process(contract_config_typemonte.contract_config),
            'pays'                                          : contract_config_typemonte.pays
        }
    }

    async fetchWithTypeMonte (col) {
        return col.with({
            'contract_type_monte'                     : 'contractconfigtypemonte_typemonte'
        })
    }

    async transformWithTypeMonte (contract_config_typemonte) {
        return {
            'contractconfigtypemonte_id'                    : contract_config_typemonte.contractconfigtypemonte_id,
            'contractconfigtypemonte_label'                 : contract_config_typemonte.contractconfigtypemonte_label,
            'contractconfigtypemonte_comment'               : contract_config_typemonte.contractconfigtypemonte_comment,
            'contractconfigtypemonte_contractconfig'        : contract_config_typemonte.contractconfigtypemonte_contractconfig,
            'contractconfigtypemonte_available_ca'          : contract_config_typemonte.contractconfigtypemonte_available_ca,
            'contractconfigtypemonte_paillette'             : contract_config_typemonte.contractconfigtypemonte_paillette,
            'contractconfigtypemonte_archive'               : contract_config_typemonte.contractconfigtypemonte_archive,
            'contract_type_monte'                           : await ContractTypeMonteTransformer.process(contract_config_typemonte.contract_type_monte),
            'contract_config'                               : await ContractConfigTransformer.process(contract_config_typemonte.contract_config, 'withSeason')
        }
    }

    async transformLight (contract_config_typemonte) {
        return {
            'contractconfigtypemonte_id'                    : contract_config_typemonte.contractconfigtypemonte_id,
            'contractconfigtypemonte_label'                 : contract_config_typemonte.contractconfigtypemonte_label,
            'contractconfigtypemonte_comment'               : contract_config_typemonte.contractconfigtypemonte_comment,
            'contractconfigtypemonte_contractconfig'        : contract_config_typemonte.contractconfigtypemonte_contractconfig,
            'contractconfigtypemonte_typemonte'             : contract_config_typemonte.contractconfigtypemonte_typemonte,
            'contractconfigtypemonte_archive'               : contract_config_typemonte.contractconfigtypemonte_archive
        }
    }
}
