import Transformer from './Transformer'
import ArticleTransformer from '@/assets/js/dexie/transformers/ArticleTransformer.js'
/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractConfigConditionsArticlesTransformer extends Transformer {

	async fetch (col) {
	    return col.with({
	        'articles'                     : 'contractconfigconditionsarticles_articles'
	    })
	}

    async transform (conditions_articles) {
        return {
            'contractconfigconditionsarticles_id'					: conditions_articles.contractconfigconditionsarticles_id,
            'contractconfigconditionsarticles_articles'				: conditions_articles.contractconfigconditionsarticles_articles,
            'contractconfigconditionsarticles_htunit'               : conditions_articles.contractconfigconditionsarticles_htunit,
            'contractconfigconditionsarticles_ht'                   : conditions_articles.contractconfigconditionsarticles_ht,
            'contractconfigconditionsarticles_qte'                  : conditions_articles.contractconfigconditionsarticles_qte,
            'contractconfigconditionsarticles_ttc'                  : conditions_articles.contractconfigconditionsarticles_ttc,
            'articles'												: await ArticleTransformer.process(conditions_articles.articles, 'withAccountingPlan'),
        }
    }
}
