import Transformer from './Transformer'
import Vue from 'vue'

export default class ContractTrackingTransformer extends Transformer {

    table = 'contract_tracking'

    async transform (contract_tracking) {
        return {
            contracttracking_id: contract_tracking.contracttracking_id,
            contracttracking_contract: contract_tracking.contracttracking_contract,
            contracttracking_type: contract_tracking.contracttracking_type,
            contracttracking_date: contract_tracking.contracttracking_date
        }
    }
}