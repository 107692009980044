
import Constants from 'Constants'
import Countries from 'GroomyRoot/assets/lang/countries/countries'
import Common from '@/assets/js/common'
import Transformer from './Transformer'
import Vue from 'vue'
import GroomyConfig from '@/assets/js/dexie/GroomyConfig'
import ContractAvenantTransformer from '@/assets/js/dexie/transformers/ContractAvenantTransformer.js'
import ContractAvenantArticlesTransformer from '@/assets/js/dexie/transformers/ContractAvenantArticlesTransformer.js'
import ContractTiersTransformer from '@/assets/js/dexie/transformers/ContractTiersTransformer.js'
import ContractConfigTransformer from '@/assets/js/dexie/transformers/ContractConfigTransformer.js'
import ContractTrackingTransformer from '@/assets/js/dexie/transformers/ContractTrackingTransformer.js'

import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
import SeasonMareStallionTransformer from '@/assets/js/dexie/transformers/SeasonMareStallionTransformer.js'
import HorseTransformer from './HorseTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export class ContractTransformer extends Transformer {

	table = 'contract'
	transactionTables = [
		'contract_tiers',
		'contract_avenant',
		'contract_avenant_articles',
		'contract_avenant_status',
		'contract_avenant_type',
		'contract_config',
		'contract_config_type_monte',
		'horse',
		'tiers',
		'saillie',
		'season'
	]

	getTrad(key) {
		return Vue.i18n.translate(key)
	}

	async last_avenant(contract) {
		return this.fetchRelationship(contract, 'contract_avenant')
		.then((avenants) => {
			if (avenants.length === 0) {
				return null
			}
			return avenants.sortBy('avenant_num', 'DESC')[0]
		})
	}

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
	async avenant(contract) {
		const last_avenant = await this.fetchAdditionalColumn(contract, 'last_avenant')
		return ContractAvenantTransformer.process(last_avenant, 'all')
	}

	async contractConfigUltraLight (contract) {
		return this.fetchRelationship(contract, 'contract_contractconfig')
		.then((config) => {
			if(config == undefined)
			{
				return {}
			}

		    return {
		        'contractconfig_id'             : config.contractconfig_id,
		        'contractconfig_horse'          : config.contractconfig_horse,
		    }
		})
	}

	async tiers (contract) {
		return this.fetchRelationship(contract, 'contract_tiers')
		.then((tiers) => {
			if(tiers.length == 0)
			{
				return {}
			}

		    return {
		        'contracttiers_id'             : tiers[0].contracttiers_id,
		        'contracttiers_tiers'          : tiers[0].contracttiers_tiers,
		    }
		})
	}

	async contract_tiers(contract) {
		const contract_tiers = await this.fetchRelationship(contract, 'contract_tiers')
		return ContractTiersTransformer.process(contract_tiers, 'withTiersAndTiersCountry')
	}

	async courtier(contract) {
		const contract_courtier = await this.fetchRelationship(contract, 'contract_courtier')
		return TierTransformer.process(contract_courtier, 'withPhone')
	}

	async config(contract) {
		const config = await this.fetchRelationship(contract, 'contract_contractconfig')
		return config ? await ContractConfigTransformer.process(config) : {}
	}

	async fetch (col) {
		this.additionalColumns({
			last_avenant: this.last_avenant,
		})

		return col.with({
			'tiers'                     : 'contract_tiers',
			'config'                    : 'contract_contractconfig',
			'courtier'                  : 'contract_courtier',
		})
	}


	async transform (contract) {
		return {
			'contract_id'               : contract.contract_id,
			'contract_num'              : contract.contract_num,
			'contract_note'             : contract.contract_note,
			'contract_contractconfig'   : contract.contract_contractconfig,
			'avenant'                   : await ContractAvenantTransformer.process(contract.last_avenant, 'all'),
			'contract_tiers'            : await ContractTiersTransformer.process(contract.tiers, 'withTiers'), //Avant il y avait pas de light => Optimisaition car on allait rechercher toutes les infos
			'courtier'                  : await TierTransformer.process(contract.courtier, 'light'),
			'config'                    : contract.config.contractconfig_id ? await ContractConfigTransformer.process(contract.config) : {},
			'contract_paillette'		: contract.contract_paillette,
			'contract_created'			: contract.contract_created
		}
	}

	async fetchAll (col) {
		return col.with({
			'tiers'                     : 'contract_tiers',
			'config'                    : 'contract_contractconfig',
			'courtier'                  : 'contract_courtier',
			'avenant'					: 'contract_avenant'
		})
	}


	async transformAll (contract) {
		return {
			'contract_id'               : contract.contract_id,
			'contract_num'              : contract.contract_num,
			'contract_note'             : contract.contract_note,
			'contract_contractconfig'   : contract.contract_contractconfig,
			'avenant'                   : await ContractAvenantTransformer.process(contract.avenant, 'all'),
			'contract_tiers'            : await ContractTiersTransformer.process(contract.tiers),
			'courtier'                  : await TierTransformer.process(contract.courtier, 'light'),
			'config'                    : await ContractConfigTransformer.process(contract.config),
			'contract_paillette'		: contract.contract_paillette
		}
	}

	async fetchWithoutTiers (col) {
		this.additionalColumns({
			last_avenant: this.last_avenant
		})

		return col.with({
			'courtier'                  : 'contract_courtier',
			'config'                    : 'contract_contractconfig'
		})
	}


	async transformWithoutTiers (contract) {
		return {
			'contract_id'				: contract.contract_id,
			'contract_num'				: contract.contract_num,
			'contract_note'				: contract.contract_note,
			'config'					: await ContractConfigTransformer.process(contract.config),
			'avenant'					: await ContractAvenantTransformer.process(contract.last_avenant, 'all'),
			'courtier'					: await TierTransformer.process(contract.courtier, 'light'),
			'contract_paillette'		: contract.contract_paillette
		}
	}

	async fetchWithLastAvenant (col) {
		this.additionalColumns({
			last_avenant: this.last_avenant
		})

		return col.with({
			'courtier'                  : 'contract_courtier',
			'config'                    : 'contract_contractconfig',
			'tiers'                     : 'contract_tiers',
		})
	}


	async transformWithLastAvenant (contract) {
		return {
			'contract_id'               : contract.contract_id,
			'contract_num'              : contract.contract_num,
			'contract_note'             : contract.contract_note,
			'contract_contractconfig'   : contract.contract_contractconfig,
			'config'                    : await ContractConfigTransformer.process(contract.config),
			'contract_tiers'            : await ContractTiersTransformer.process(contract.tiers),
			'last_avenant'              : await ContractAvenantTransformer.process(contract.last_avenant, 'all'),
			'courtier'                  : await TierTransformer.process(contract.courtier, 'light'),
			'contract_paillette'		: contract.contract_paillette
		}
	}

	async fetchWithLastAvenantLight (col) {
		this.additionalColumns({
			last_avenant: this.last_avenant
		})

		return col
	}

	async transformWithLastAvenantLight (contract) {
		return {
			'contract_id'               : contract.contract_id,
			'last_avenant'              : await ContractAvenantTransformer.process(contract.last_avenant, 'allLight'),
		}
	}


	async fetchWithContractConfig (col) {
		return col.with({
			'tiers'                     : 'contract_tiers',
			'config'                    : 'contract_contractconfig'
		})
	}


	async transformWithContractConfig (contract) {
		return {
			'contract_id'				: contract.contract_id,
			'contract_num'				: contract.contract_num,
			'contract_note'				: contract.contract_note,
			'contract_tiers'            : await ContractTiersTransformer.process(contract.tiers),
			'config'					: await ContractConfigTransformer.process(contract.config),
			'contract_paillette'		: contract.contract_paillette
		}
	}


	async fetchOnlyAvenantId (col) {
		return col.with({
			avenant: 'contract_avenant'
		})
	}

	async transformOnlyAvenantId (contract) {
		return {
			'contract_id'				: contract.contract_id,
			'contract_num'				: contract.contract_num,
			'contract_note'				: contract.contract_note,
			'avenant'					: await ContractAvenantTransformer.process(contract.avenant, 'ultraLight'),
		}
	}


	async fetchWithoutAvenant (col) {
		return col.with({
			'courtier'                  : 'contract_courtier',
			'config'                    : 'contract_contractconfig',
			'tiers'                     : 'contract_tiers',
		})
	}




	async transformWithoutAvenant (contract) {
		return {
			'contract_id'				: contract.contract_id,
			'contract_num'				: contract.contract_num,
			'contract_note'				: contract.contract_note,
			'config'                    : await ContractConfigTransformer.process(contract.config),
			'contract_tiers'            : await ContractTiersTransformer.process(contract.tiers),
			'courtier'                  : await TierTransformer.process(contract.courtier, 'light'),
			'contract_paillette'		: contract.contract_paillette
		}
	}

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
	async fractions (contract) {
		const avenants = await this.fetchRelationship(contract, 'contract_avenant')

		const fractions = await this.getFractions(avenants)

		return fractions[contract.contract_id] || {
			total: await Common.priceFormat(0),
			reservation: await Common.priceFormat(0),
			facture_reservation: {
				encaisse: await Common.priceFormat(0),
				solde: await Common.priceFormat(0)
			},
			ft: await Common.priceFormat(0),
			facture_ft: {
				encaisse: await Common.priceFormat(0),
				solde: await Common.priceFormat(0)
			},
			solde1octobre: await Common.priceFormat(0),
			facture_1octobre: {
				encaisse: await Common.priceFormat(0),
				solde: await Common.priceFormat(0)
			},
			soldepv: await Common.priceFormat(0),
			facture_pv: {
				encaisse: await Common.priceFormat(0),
				solde: await Common.priceFormat(0)
			},
			facture_saillie: {
				encaisse: await Common.priceFormat(0),
				solde: await Common.priceFormat(0)
			},
			solde: await Common.priceFormat(0),
			a_facturer: await Common.priceFormat(0)
		}
	}

	// Fractions gérant la récupération de plusieurs contrats simulatanément
	// Inutile dans la configuration actuelle car on utilise la valeur indexée
	// mais déjà développé donc je l'ai laissé
	async getFractions(avenants) {
		// contract_id en clé et un objet avec les fractions en valeurs
		let fractions = {}

		const avenant_ids = avenants.map(a => (a.avenant_id))
		avenants = this._mapArrayToObject(avenants, 'avenant_id')

		const articles = await this.db().t('contract_avenant_articles')
		.then(table => {
			return table.where('avenantarticles_avenant').anyOf(avenant_ids)
			.transform(new ContractAvenantArticlesTransformer())
		})

		await Common.asyncForEach(articles, async (article) => {
			const avenant = avenants[article.avenantarticles_avenant]
			const c_id = avenant.avenant_contract

			// Initialiser l'objet
			if(!fractions[c_id]) {
				fractions[c_id] = {
					0: 0, // Total
					1: 0, // réservation
					2: 0, // FT
					3: 0, // 1er Octobre
					4: 0, // PV
					5: 0, // SOLDES 1er Octobre + PV
					6: 0, // Reste à facturer
				}
			}

			if(article.conditions.length != 0)
			{
				fractions[c_id][article.conditions.contract_conditions.contractconditions_id] += article.avenantarticles_ht
				fractions[c_id][0] += article.avenantarticles_ht
				if(article.conditions.contract_conditions.contractconditions_id == 3 || article.conditions.contract_conditions.contractconditions_id == 4) fractions[c_id][5] +=article.avenantarticles_ht
			}

			if(article.conditions_solo !== null && article.conditions_solo.length != 0)
			{
				fractions[c_id][article.conditions_solo.contractconditions_id]+=article.avenantarticles_ht
				fractions[c_id][0] += article.avenantarticles_ht
				if(article.conditions_solo.contractconditions_id == 3 || article.conditions_solo.contractconditions_id == 4) fractions[c_id][5] += article.avenantarticles_ht
			}

			// Reste à facturer
			if(article.avenantarticles_invoiceable == 0) {
				fractions[c_id][6] += article.avenantarticles_ht
			}
		})

		// Formattage des fractions
		let result = {}

		await Common.asyncForEach(Object.keys(fractions), async (c_id) => {
			result[c_id] = {
				total			: await Common.priceFormat(fractions[c_id][0] / 100),
				reservation		: await Common.priceFormat(fractions[c_id][1] / 100),
				ft				: await Common.priceFormat(fractions[c_id][2] / 100),
				solde1octobre	: await Common.priceFormat(fractions[c_id][3] / 100),
				soldepv			: await Common.priceFormat(fractions[c_id][4] / 100),
				solde			: await Common.priceFormat(fractions[c_id][5] / 100),
				a_facturer		: await Common.priceFormat(fractions[c_id][6] / 100),
				facture_reservation: {
					encaisse: await Common.priceFormat(0),
					solde: await Common.priceFormat(0)
				},
				facture_ft: {
					encaisse: await Common.priceFormat(0),
					solde: await Common.priceFormat(0)
				},
				facture_1octobre: {
					encaisse: await Common.priceFormat(0),
					solde: await Common.priceFormat(0)
				},
				facture_pv: {
					encaisse: await Common.priceFormat(0),
					solde: await Common.priceFormat(0)
				},
				facture_saillie: {
					encaisse: await Common.priceFormat(0),
					solde: await Common.priceFormat(0)
				}
			}
		})

		return result
	}

	async stallion(item) {
		if(!item.contract_contractconfig) {
			return []
		}

		let contractconfig = await this.fetchRelationship(item, 'contract_contractconfig')

		return this.db().t('horse')
		.then(table => {
            return table.where({
				horse_id: parseInt(contractconfig.contractconfig_horse)
			})
		})
		.then(col => {
			return col.transform(new HorseTransformer('light'))
		})
	}

	async mare(item) {
		let avenant = await this.fetchAdditionalColumn(item, 'last_avenant')

		if(!avenant || !avenant.avenant_horse){
			return []
		}

		return this.db().t('horse')
		.then(table => {
            return table.where({
				horse_id: parseInt(avenant.avenant_horse)
			})
		})
		.then(col => {
			return col.transform(new HorseTransformer('light'))
		})
	}

	async stringified(item) {
		let stringified = await super.stringified(item)

		const config = await this.fetchAdditionalColumn(item, 'config')
		const stallion = config ? config.horse : null
		const mare = item.avenant ? item.avenant.horse : null
		const contract_tiers = await this.fetchAdditionalColumn(item, 'contract_tiers')
		const tiers = contract_tiers[0] ? contract_tiers[0].tiers : null
		const season = config ? config.season : null
		const season_label = season ? season.season_label : ''

		if(stallion && mare && tiers) {
			return `${stringified}|${mare.horse_nom}|${stallion.horse_nom}|${tiers.tiers_rs}|${tiers.pays}|${season_label}`
		}
		else if(stallion && tiers) {
			return `${stringified}|${stallion.horse_nom}|${tiers.tiers_rs}|${tiers.pays}|${season_label}`
		}
		else if(mare && tiers) {
			return `${stringified}|${mare.horse_nom}|${tiers.tiers_rs}|${tiers.pays}|${season_label}`
		}
		else if(stallion && mare) {
			return `${stringified}|${mare.horse_nom}|${stallion.horse_nom}|${season_label}`
		}
		else if(stallion) {
			return `${stringified}|${stallion.horse_nom}|${season_label}`
		}
		else if(mare) {
			return `${stringified}|${mare.horse_nom}`
		}
		else if(tiers.length > 0) {
			return `${stringified}|${tiers.tiers_rs}|${tiers.pays}|${season_label}`
		}
	}

	async seasonmarestallion(item) {
		const mare_id = item.avenant ? item.avenant.avenant_horse : null
		const season_id = item.config ? item.config.contractconfig_season : null
		const stallion_id = item.config ? item.config.contractconfig_horse : null
		if(!mare_id || !season_id || !stallion_id) {
			return {}
		}

		let season_mare = await this.db().t('season_mare')
			.then(table => {
				return table
				.where({
					seasonmare_season: season_id,
					seasonmare_horse: mare_id
				})
				.primaryKeys()
			})
			.then(seasonmare_ids => {
				return seasonmare_ids.length > 0 ? seasonmare_ids[0] : null
			})

		if(!season_mare) {
			return {}
		}

		let sms = await this.db().t('season_mare_stallion')
			.then(table => {
				return table.where({
					seasonmarestallion_seasonmare: season_mare,
					seasonmarestallion_horse: stallion_id,
					seasonmarestallion_archive: 0
				})
			})
			.then(col => {
				return col.first()
			})
			.then(sms => {
				return SeasonMareStallionTransformer.process(sms, 'withLastSaut')
			})

		return sms
	}

	async getSeasonMares(mare_ids, season_ids) {

		return this.db().t('season_mare')
		.then(table => {
			return table
			.where('seasonmare_horse').anyOf(mare_ids)
			.and(sm => (season_ids.includes(sm.seasonmare_season)))
			.toArray()
		})
	}

	async getSeasonMareStallions(season_mares, stallion_ids) {
		const seasonmare_ids = season_mares.map(sm => (sm.seasonmare_id))

		return await this.db().t('season_mare_stallion')
		.then(table => {
			return table.where('seasonmarestallion_seasonmare').anyOf(seasonmare_ids)
			.and(sms => (stallion_ids.includes(sms.seasonmarestallion_horse)))
			.and(sms => sms.seasonmarestallion_archive == 0)
			.transform(new SeasonMareStallionTransformer('withLastSaut'))
		})
	}

	async getSeasonMareStallionsPorteuse(season_mares_donneuse, season_mares_porteuse, stallion_ids) {
		const seasonmare_donneuse_ids = season_mares_donneuse.map(sm => (sm.seasonmare_id))
		const seasonmare_porteuse_ids = season_mares_porteuse.map(sm => (sm.seasonmare_id))

		return await this.db().t('season_mare_stallion')
		.then(table => {
			return table.where('seasonmarestallion_porteuse').anyOf(seasonmare_porteuse_ids)
			.and(sms => (seasonmare_donneuse_ids.includes(sms.seasonmarestallion_seasonmare)))
			.and(sms => (stallion_ids.includes(sms.seasonmarestallion_horse)))
			.and(sms => sms.seasonmarestallion_archive == 0)
			.transform(new SeasonMareStallionTransformer('withLastSaut'))
		})
	}

	async getInvoices(contract_ids) {
		const licence_key = await GroomyConfig.getItem('licence_key')

		const body = {
			contract_ids: contract_ids
		}

		const url = Common.constructRoute(Constants.CONTRACT_BULK_INVOICES_POST, {} ) + '?licence_key=' + licence_key

		return await this.api_requests().request_post_api('ContractTransformer::getInvoices', url, body, false)
		.then(response => {
			return response.retour
		})
		.catch(err => {
			console.error(err)
			return []
		})
	}

	async resolveWithAllAvenantArticles(col, ctx) {

		const contracts = await col.with({
			'saillie' 		: 'saillie',
		})

		// je vire les contrats qui n'ont pas de config (merci GFE)
		// const contracts = contracts_first.filter(contract => contract.contract_contractconfig)

		const contract_ids = contracts.map(c => (c.contract_id))
		const contractconfig_ids = []
		const courtier_ids = []

		contracts.forEach(contract => {
			if(contract.contract_contractconfig) {
				contractconfig_ids.push(contract.contract_contractconfig)
			}

			if(contract.contract_courtier) {
				courtier_ids.push(contract.contract_courtier)
			}
		})

		let promises = []

		let configs = []
		let tiers = []
		let courtiers = []
		let season_mares = []
		let season_mares_porteuse = []
		let season_mares_donneuse = []
		let season_mare_stallions = []
		let season_mare_stallions_porteuse = []
		let invoices = []
		let trackings = []
		let documents = []

		//Test des collones
		let is_configs=true
		let is_avenant=true
		let is_fractions=true
		let is_tiers=true
		let is_courtiers=true
		let is_seasonmarestallion=true
		let is_formatted_invoices=true

		if(ctx != undefined && ctx.columns != undefined && ctx.columns != null){ //si ctx existe et que ctx.columns existe avec des infos
			is_configs =  ctx.columns.find(col => (col.startsWith('config') || col.startsWith('seasonmarestallion') )) ? true : false
			is_avenant =  ctx.columns.find(col => col.startsWith('avenant') ) ? true : false
			is_fractions =  ctx.columns.find(col => (col.startsWith('fractions') || col.startsWith('articles') )) ? true : false
			is_tiers =  ctx.columns.find(col => col.startsWith('contract_tiers[0].tiers') ) ? true : false
			is_courtiers =  ctx.columns.find(col => col.startsWith('courtier')) ? true : false
			is_seasonmarestallion =  ctx.columns.find(col => col.startsWith('seasonmarestallion')) ? true : false
			is_formatted_invoices =  ctx.columns.find(col => col.startsWith('formatted_invoices')) ? true : false
		}



		// Configs

		if(is_configs){
			promises.push(
				this.db().t('contract_config')
				.then(table => {
					return table.where('contractconfig_id').anyOf(contractconfig_ids).transform(new ContractConfigTransformer('withSeasonAndHorse'))
				})
				.then(arr => {
					configs = this._mapArrayToObject(arr, 'contractconfig_id')
				})
			)
		}

		// Pour forcer l'indexation des lignes

		let additionalColumns = {}

		// if(is_avenant){
		// 	additionalColumns.avenant = this.avenant
		// }

		// if(is_fractions){
		// 	additionalColumns.fractions = this.fractions
		// }

		this.additionalColumns(additionalColumns)

		contracts.forEach((contract) => {
			promises.push(
				this.fetchAdditionalColumns(contract)
			)
		})

		await Promise.all(promises)

		promises = []

		// Contract Tiers
		if(is_tiers){
			promises.push(
				this.db().t('contract_tiers')
				.then(table => {
					return table.where('contracttiers_contract').anyOf(contract_ids).transform(new ContractTiersTransformer('withTiersAndPhone'))
				})
				.then(arr => {
					tiers = arr
				})
			)
		}

		// Courtier
		if(is_courtiers){
			promises.push(
				this.db().t('tiers')
				.then(table => {
					return table.where('tiers_id').anyOf(courtier_ids).transform(new TierTransformer('onlyRS'))
				})
				.then(arr => {
					courtiers = arr
				})
			)
		}

		// SeasonMareStallions
		if(is_seasonmarestallion){
			promises.push((async () => {
				const mare_ids = []
				const mare_donneuse_ids = []
				const mare_porteuse_ids = []
				const season_ids = []
				const stallion_ids = []

				contracts.forEach(contract => {
					if(contract.season_mare_stallion) {
						return
					}
					
					if(contract.avenant && contract.avenant.avenant_transfert) {
						if(contract.avenant && contract.avenant.avenant_porteuse) {
							mare_porteuse_ids.push(contract.avenant.avenant_porteuse)
							mare_donneuse_ids.push(contract.avenant.avenant_horse)
						}
					}
					else {
						if(contract.avenant && contract.avenant.avenant_horse) {
							mare_ids.push(contract.avenant.avenant_horse)
						}
					}

					const config = configs[contract.contract_contractconfig]

					if(config
						&& config.contractconfig_season
						&& config.contractconfig_horse
					) {
						season_ids.push(config.contractconfig_season)
						stallion_ids.push(config.contractconfig_horse)
					}
				})

				season_mares = await this.getSeasonMares(mare_ids, season_ids)
				if(season_mares.length > 0) {
					season_mare_stallions = await this.getSeasonMareStallions(season_mares.filter(mare => mare), stallion_ids)
				}

				season_mares_porteuse = await this.getSeasonMares(mare_porteuse_ids.filter(mare => mare), season_ids)
				season_mares_donneuse = await this.getSeasonMares(mare_donneuse_ids.filter(mare => mare), season_ids)
				if(season_mares_donneuse.length > 0 && season_mares_porteuse.length > 0) {
					season_mare_stallions_porteuse = await this.getSeasonMareStallionsPorteuse(season_mares_donneuse, season_mares_porteuse, stallion_ids)
				}

				season_mare_stallions = [...season_mare_stallions, ...season_mare_stallions_porteuse]
			})())
		}

		// Factures impayées sur les contrats
		if(is_formatted_invoices){
			promises.push(
				this.getInvoices(contract_ids)
				.then(arr => {
					invoices = arr
				})
			)
		}

		promises.push(
			this.db().t('contract_tracking')
				.then(table => {
					return table.where('contracttracking_contract').anyOf(contract_ids).transform(new ContractTrackingTransformer())
				})
				.then(arr => {
					trackings = arr
				})
		)

		promises.push(
			this.db().t('contract_document')
				.then(table => {
					return table.where('contractdocument_contract').anyOf(contract_ids).toArray()
				})
				.then(arr => {
					documents = arr
				})
		)

		await Promise.all(promises)
		return contracts.map(contract => {
			try {
				if(!Object.prototype.hasOwnProperty.call(contract, 'fractions')) {
					// contract.fractions = this.fractions(contract)
					contract.fractions = null
				}

				if(!Object.prototype.hasOwnProperty.call(contract, 'avenant')) {
					// contract.avenant = this.avenant(contract)
					contract.avenant = null
				}

				const config = configs[contract.contract_contractconfig]
				const contract_tiers = tiers.filter(ct => (ct.contracttiers_contract === contract.contract_id))

				let tiers_address = ''
				if(contract_tiers[0]) {
					tiers_address = contract_tiers[0].tiers.tiers_address1 + ' ' + contract_tiers[0].tiers.tiers_address2 + ' ' + contract_tiers[0].tiers.tiers_postalcode + ' ' + contract_tiers[0].tiers.tiers_town
				}

				const avenant = contract.avenant

				let avenant_cmep_address = ''
				let avenant_cmep_country = ''

				if(avenant) {
					if(avenant.cmep) {
						avenant_cmep_address = avenant.cmep.tiers_address1 + ' ' + avenant.cmep.tiers_address2 + ' ' + avenant.cmep.tiers_postalcode + ' ' + avenant.cmep.tiers_town
						avenant_cmep_country = Countries[Vue.i18n.locale()].label[avenant.cmep.tiers_country]
					}

					if(avenant.avenant_dn) {
						avenant.avenant_dn = Date.parseTz(avenant.avenant_dn)
					}
					
					if(avenant.status) {
						avenant.status.avenantstatus_label = this.getTrad('monte.avenantstatus_labels.' + avenant.status.avenantstatus_type)
					}

					if(avenant.avenant_revue_bool && seasonmarestallion) {
						seasonmarestallion.seasonmarestallion_status = this.getTrad('monte.avenant_revue')
					}

					if(avenant.avenant_type_contrat) {
						avenant.avenant_type_contrat = this.getTrad('monte.' + avenant.avenant_type_contrat)
					}
				}

				const courtier = courtiers.find(t => (t.tiers_id === contract.contract_courtier))
				const contract_fractions = contract.fractions

				const contract_invoices = invoices.filter(invoice => (invoice.contract_ids.includes(contract.contract_id)))

				const contract_trackings = trackings.filter(tracking => tracking.contracttracking_contract == contract.contract_id)
				const mails_sent = contract_trackings.filter(tracking => tracking.contracttracking_type == "mail")
				let tracking_mails_date = Common.getArrayObjProperty(mails_sent, "contracttracking_date")
				if(tracking_mails_date.length == 0) {
					tracking_mails_date = ""
				}

				const mails_received = contract_trackings.filter(tracking => tracking.contracttracking_type == "received")
				let tracking_received_date = Common.getArrayObjProperty(mails_received, "contracttracking_date")
				if(tracking_received_date.length == 0) {
					tracking_received_date = ""
				}

				const signature = contract_trackings.filter(tracking => tracking.contracttracking_type == "signature")
				let tracking_signature_date = Common.getArrayObjProperty(signature, "contracttracking_date")
				if(tracking_signature_date.length == 0) {
					tracking_signature_date = ""
				}

				let seasonmarestallion = contract.season_mare_stallion
				if(config && avenant && !seasonmarestallion) {
					let season_mare = null
					let season_mare_porteuse = null
					if(avenant.avenant_transfert) {
						season_mare = season_mares_donneuse.find(sm => (
							sm.seasonmare_season === config.contractconfig_season
							&& sm.seasonmare_horse === avenant.avenant_horse
						))

						season_mare_porteuse = season_mares_porteuse.find(sm => (
							sm.seasonmare_season === config.contractconfig_season
							&& sm.seasonmare_horse === avenant.avenant_porteuse
						))
					}
					else {
						season_mare = season_mares.find(sm => (
							sm.seasonmare_season === config.contractconfig_season
							&& sm.seasonmare_horse === avenant.avenant_horse
						))
					}

					if(season_mare) {
						if(avenant.avenant_transfert && season_mare_porteuse) {
							seasonmarestallion = season_mare_stallions.find(sms => (
								sms.seasonmarestallion_seasonmare === season_mare.seasonmare_id
								&& sms.seasonmarestallion_horse === config.contractconfig_horse
								&& sms.seasonmarestallion_porteuse === season_mare_porteuse.seasonmare_id
							))
						}
						else {
							seasonmarestallion = season_mare_stallions.find(sms => (
								sms.seasonmarestallion_seasonmare === season_mare.seasonmare_id
								&& sms.seasonmarestallion_horse === config.contractconfig_horse
							))
						}
					}
				}

				if(seasonmarestallion && !seasonmarestallion.dernier_saut) {
					// solution rétroactive => seasonmarestallion.dernier_saut.actes_date déjà dans des config perso
					seasonmarestallion.dernier_saut = {actes_date: seasonmarestallion.seasonmarestallion_dds}
				}

				let customClasses = null
				if(seasonmarestallion) {
					customClasses = {
						'seasonmarestallion.seasonmarestallion_status': seasonmarestallion.seasonmarestallion_status.indexOf('DG+') != -1 ? 'green' : ''
					}
				}

				const contract_document = documents.filter(doc => doc.contractdocument_contract == contract.contract_id)

				let paillettes_date = ''
				let regex = /(([0-9]{2}\/){2}[0-9]{4})/g
				if(contract.paillettes) {
					const matches = contract.paillettes.date_send.match(regex)
					if(matches) {
						matches.forEach(match => {
							const numbers = match.split('/')
							let date = new Date(numbers[0] + '/' + numbers[1] + ' /' + numbers[2])
							if(date == 'Invalid Date') {
								date = new Date(numbers[1] + '/' + numbers[0] + ' /' + numbers[2])
							}
							contract.paillettes.date_send.replace(match, Date.parseTz(date).toLocaleDateString())
						})
						paillettes_date = contract.paillettes.date_send
					}
				}

				let doses_date = ''
				if(contract.doses) {
					const matches = contract.doses.date_send.match(regex)
					if(matches) {
						matches.forEach(match => {
							const numbers = match.split('/')
							let date = new Date(numbers[0] + '/' + numbers[1] + ' /' + numbers[2])
							if(date == 'Invalid Date') {
								date = new Date(numbers[1] + '/' + numbers[0] + ' /' + numbers[2])
							}
							contract.doses.date_send.replace(match, Date.parseTz(date).toLocaleDateString())
						})
						doses_date = contract.doses.date_send
					}
				}

				return {
					'contract_id'				: contract.contract_id,
					'contract_num'				: (contract.contract_num == null) ? Vue.i18n.translate("monte.contrat_brouillon_non_numerote") : contract.contract_num,
					'contract_note'				: contract.contract_note ? contract.contract_note.replace(/(<([^>]+)>)/gi, "") : "",
					'contract_updated'			: contract.contract_updated,
					'contract_sent'				: Boolean(contract.contract_sent),
					'contract_username'			: contract.contract_username,
					'config'                    : config,
					'contract_tiers'            : contract_tiers,
					'avenant'              		: avenant,
					'saillie'					: contract.saillie,
					'fractions'					: contract_fractions || {},
					'articles'					: contract_fractions ? contract_fractions.total : null,
					'formatted_invoices'		: contract_invoices,
					'seasonmarestallion'		: seasonmarestallion,
					'seasonmarestallion_dt_date': seasonmarestallion ? seasonmarestallion.seasonmarestallion_dt : "",
					'seasonmarestallion_dps_date': seasonmarestallion ? seasonmarestallion.seasonmarestallion_dps : "",
					'contract_clientarea' 		: !!contract.contract_clientarea,
					'courtier'					: courtier,
					'tiers_address'				: tiers_address,
					'avenant_cmep_address'		: avenant_cmep_address,
					'avenant_cmep_country'		: avenant_cmep_country,
					'contract_paillette'		: contract.contract_paillette,
					'paillettes_ratio'			: contract.paillettes ? (contract.paillettes.total > 0 ? contract.paillettes.total_send + " / " + contract.paillettes.total : '') : '',
					'paillettes_date'			: paillettes_date,
					'invoices_percentage'		: contract.invoices ? contract.invoices
													.map(invoice => (`${invoice.invoice_num} (${Vue.i18n.translate('invoice.invoice_balance')}: ${invoice.invoice_encaisse})`))
													.join(', ') : '',
					'porteurs_parts'			: contract.porteurs_parts,
					'tracking_mails_date'		: tracking_mails_date ? tracking_mails_date : '',
					'tracking_received_date'	: tracking_received_date ? tracking_received_date : '',
					'tracking_signature_date' 	: tracking_signature_date ? tracking_signature_date : '',
					'contract_created'			: contract.contract_created,
					'doses_ratio'				: contract.doses ? (contract.doses.total > 0 ? contract.doses.total_send + " / " + contract.doses.total : '') : '',
					'doses_date'				: doses_date,
					'customClasses'				: customClasses,
					'contract_document'			: contract_document.length > 0,
					'contract_contractconfig' 	: contract.contract_contractconfig
				}
			} catch (error) {
				console.log(error)
				console.log(JSON.stringify(contract))
			}
		})
	}

	async fetchWithAllAvenantArticles (col) {
		this.additionalColumns({
			// fractions: this.fractions,
			// avenant: this.avenant,
			config: this.config,
			contract_tiers: this.contract_tiers,
			seasonmarestallion: this.seasonmarestallion,
			courtier: this.courtier
		})

		return col.with({
			'saillie'                   : 'saillie',
		})
    }

	async transformWithAllAvenantArticles (contract) {
		return {
			'contract_id'				: contract.contract_id,
			'contract_num'				: (contract.contract_num == null) ? Vue.i18n.translate("monte.contrat_brouillon_non_numerote") : contract.contract_num,
			'contract_note'				: contract.contract_note ? contract.contract_note.replace(/(<([^>]+)>)/gi, "") : "",
			'contract_inserted'			: contract.contract_inserted,
			'config'                    : contract.config,
			'contract_tiers'            : contract.contract_tiers,
			'avenant'              		: contract.avenant,
			'articles'					: contract.fractions.total,
			'contract_updated'			: contract.contract_updated,
			'saillie'					: contract.saillie,
			'fractions'					: contract.fractions,
			'invoices'					: contract.invoices,
			'seasonmarestallion'		: contract.seasonmarestallion,
			'contract_clientarea' 		: contract.contract_clientarea ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non'),
			'courtier'					: contract.courtier,
			'contract_paillette'		: contract.contract_paillette,
			'paillettes_ratio'			: contract.paillettes ? (contract.paillettes.total > 0 ? contract.paillettes.total_send + " / " + contract.paillettes.total : '') : '',
			'paillettes_date'			: contract.paillettes ? contract.paillettes.date_send : ''
		}

	}
}

export default ContractTransformer
