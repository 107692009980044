import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractConditionsTransformer extends Transformer {

    async transform (contract_conditions) {
        return {
            'contractconditions_id'                 : contract_conditions.contractconditions_id,
            'contractconditions_label'              : contract_conditions.contractconditions_label,
            'contractconditions_type'               : contract_conditions.contractconditions_type,
        }
    }
}
