import Transformer from './Transformer'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
import ContractTransformer from '@/assets/js/dexie/transformers/ContractTransformer.js'
/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractTiersTransformer extends Transformer {

	async fetch (col) {
	    return col.with({
            'tiers'					: 'contracttiers_tiers',
            'contract'              : 'contracttiers_contract'
	    })
	}


    async transform (contracttiers) {
        return {
            'contracttiers_id'				: contracttiers.contracttiers_id,
            'contract'		                : await ContractTransformer.process(contracttiers.contract, 'withoutTiers'),
            'contracttiers_tiers'			: contracttiers.contracttiers_tiers,
            'tiers'							: await TierTransformer.process(contracttiers.tiers, 'light')
        }
    }


    async fetchLight (col) {
        return col.with({
            'tiers'                 : 'contracttiers_tiers',
            'contract'              : 'contracttiers_contract'
        })
    }


    async transformLight (contracttiers) {
        return {
            'contracttiers_id'              : contracttiers.contracttiers_id,
            'contracttiers_tiers'           : contracttiers.contracttiers_tiers,
            'tiers'                         : await TierTransformer.process(contracttiers.tiers, 'light')
        }
    }

    async transformUltraLight (contracttiers) {
        return {
            'contracttiers_id'              : contracttiers.contracttiers_id,
            'contracttiers_tiers'           : contracttiers.contracttiers_tiers,
            'contracttiers_contract'        : contracttiers.contracttiers_contract
        }
    }

    async fetchWithTiers (col) {
        return col.with({
            'tiers'                 : 'contracttiers_tiers'
        })
    }

    async transformWithTiers (contracttiers) {
        return {
            'contracttiers_id'              : contracttiers.contracttiers_id,
            'contracttiers_tiers'           : contracttiers.contracttiers_tiers,
            'tiers'                         : await TierTransformer.process(contracttiers.tiers, 'light')
        }
    }

	async fetchWithTiersAndTiersCountry (col) {
        return col.with({
            'tiers'                 : 'contracttiers_tiers'
        })
    }

	async transformWithTiersAndTiersCountry (contracttiers) {
        return {
            'contracttiers_id'              : contracttiers.contracttiers_id,
            'contracttiers_tiers'           : contracttiers.contracttiers_tiers,
            'contracttiers_contract'		: contracttiers.contracttiers_contract,
            'tiers'                         : await TierTransformer.process(contracttiers.tiers, 'withPhone')
        }
    }

    async fetchWithTiersAndPhone (col) {
        return col.with({
            'tiers'                 : 'contracttiers_tiers'
        })
    }

    async transformWithTiersAndPhone (contracttiers) {
        return {
            'contracttiers_id'              : contracttiers.contracttiers_id,
            'contracttiers_tiers'           : contracttiers.contracttiers_tiers,
            'contracttiers_contract'        : contracttiers.contracttiers_contract,
            'tiers'                         : await TierTransformer.process(contracttiers.tiers, 'lightWithPhone')
        }
    }
}
