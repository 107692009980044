import Transformer from './Transformer'
import ContractConfigConditionsArticlesTransformer from '@/assets/js/dexie/transformers/ContractConfigConditionsArticlesTransformer.js'
import ContractConditionsTransformer from '@/assets/js/dexie/transformers/ContractConditionsTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractConfigConditionsTransformer extends Transformer {

	table = 'contract_config_conditions'

    async articles (contract_config_condition) {
        return this.db().t('contract_config_conditions_articles')
        .then(table => {
            return table.where({
                contractconfigconditionsarticles_contractconfigconditions: contract_config_condition.contractconfigconditions_id,
                contractconfigconditionsarticles_valide: 1
            })
        })
        .then(col => {
            return col.transform(new ContractConfigConditionsArticlesTransformer())
        })
	}

	async contract_conditions(config_condition) {
		return this.fetchRelationship(config_condition, 'contractconfigconditions_conditions')
		.then(condition => {
			return ContractConditionsTransformer.process(condition)
		})
	}

	async fetch (col) {
        this.additionalColumns({
			articles: this.articles,
			contract_conditions: this.contract_conditions
        })

	    return col
	}

    async transform (contract_config_conditions) {
        return {
            'contractconfigconditions_id'					: contract_config_conditions.contractconfigconditions_id,
            'contractconfigconditions_conditions'           : contract_config_conditions.contractconfigconditions_conditions,
            'contractconfigconditions_contractconfig'       : contract_config_conditions.contractconfigconditions_contractconfig,
            'contractconfigconditions_typemonte'			: contract_config_conditions.contractconfigconditions_typemonte,
            'contract_conditions'                           : contract_config_conditions.contract_conditions,
            'conditions_articles'                           : contract_config_conditions.articles,
        }
    }
}
