import Transformer from './Transformer'
import ContractTransformer from '@/assets/js/dexie/transformers/ContractTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import ContractAvenantStatusTransformer from '@/assets/js/dexie/transformers/ContractAvenantStatusTransformer.js'
import ContractAvenantTypeTransformer from '@/assets/js/dexie/transformers/ContractAvenantTypeTransformer.js'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
import ContractConfigTypeMonteTransformer from '@/assets/js/dexie/transformers/ContractConfigTypeMonteTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractAvenantTransformer extends Transformer {

    table = 'contract_avenant'


	async fetch (col) {
	    return col.with({
	        'contract' : 'avenant_contract',
            'horse'    : 'avenant_horse',
	    })
	}

    async transform (avenant) {
        return {
            'avenant_id'                : avenant.avenant_id,
            'avenant_num'               : avenant.avenant_num,
            'avenant_justification'     : avenant.avenant_justification,
            'avenant_comment'           : avenant.avenant_comment,
            'avenant_cmep'              : avenant.avenant_cmep,
            'avenant_contract'          : avenant.avenant_contract,
            'avenant_model'          : avenant.avenant_model,
            'avenant_horse'             : avenant.avenant_horse,
            'avenant_type'              : avenant.avenant_type,
            'avenant_typemonte'         : avenant.avenant_typemonte,
            'avenant_status'            : avenant.avenant_status,
            'avenant_valide'            : avenant.avenant_valide,
            'avenant_free'              : avenant.avenant_free,
            'avenant_date'              : avenant.avenant_date,
            'avenant_date_signature'    : avenant.avenant_date_signature,
            'avenant_date_validation'   : avenant.avenant_date_validation,
            'avenant_dps'               : avenant.avenant_dps,
            'avenant_type_contrat'      : avenant.avenant_type_contrat,
            'contract'                  : await ContractTransformer.process(avenant.contract),
            'horse'                     : await HorseTransformer.process(avenant.horse, 'light'),
        }
    }

    sort (array) {
        return array.sortBy('avenant_num', 'ASC')
    }

    fetchAll (col) {
        return col.with({
            'horse'     : 'avenant_horse',
            'type'      : 'avenant_type',
            'status'    : 'avenant_status',
            'cmep'      : 'avenant_cmep',
            'type_monte': 'avenant_typemonte',
			'porteuse'	: 'avenant_porteuse'
        })
    }

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transformAll (avenant) {
        return {
            'avenant_id'                : avenant.avenant_id,
            'avenant_num'               : avenant.avenant_num,
            'avenant_justification'     : avenant.avenant_justification ? avenant.avenant_justification : "",
            'avenant_comment'           : avenant.avenant_comment ? avenant.avenant_comment : "",
            'avenant_horse'             : avenant.avenant_horse,
            'avenant_typemonte'         : avenant.avenant_typemonte,
            'avenant_contract'          : avenant.avenant_contract,
            'avenant_model'             : avenant.avenant_model,
            'avenant_free'              : avenant.avenant_free,
            'avenant_type'              : avenant.avenant_type,
            'avenant_status'            : avenant.avenant_status,
            'avenant_date_signature'    : avenant.avenant_date_signature,
            'avenant_type_contrat'      : avenant.avenant_type_contrat,
            'avenant_dps'               : avenant.avenant_dps,
            'avenant_as_bool'           : avenant.avenant_as_bool,
            'avenant_dn'                : avenant.avenant_dn,
            'avenant_dn_bool'           : avenant.avenant_dn_bool,
            'avenant_revue_bool'        : avenant.avenant_revue_bool,
            'avenant_date'              : avenant.avenant_date,
			'avenant_date_validation'   : avenant.avenant_date_validation,
			'avenant_transfert'			: avenant.avenant_transfert,
			'avenant_updated'			: avenant.avenant_updated,
			'avenant_porteuse'          : avenant.avenant_porteuse,
            'horse'                     : await HorseTransformer.process(avenant.horse, 'light'),
            'type'                      : await ContractAvenantTypeTransformer.process(avenant.type),
            'status'                    : await ContractAvenantStatusTransformer.process(avenant.status),
            'cmep'                      : await TierTransformer.process(avenant.cmep, 'light'),
            'type_monte'                : await ContractConfigTypeMonteTransformer.process(avenant.type_monte),
			'porteuse'                  : await HorseTransformer.process(avenant.porteuse, 'light'),
        }
    }

    sortAll (array) {
        return array.sortBy('avenant_num', 'ASC')
    }

    fetchAllLight (col) {
        return col.with({
            'type_monte': 'avenant_typemonte',
        })
    }

    async transformAllLight (avenant) {
        return {
            'avenant_id'            : avenant.avenant_id,
            'avenant_typemonte'     : avenant.avenant_typemonte,
            'avenant_type'          : avenant.avenant_type,
            'avenant_status'        : avenant.avenant_status,
            'avenant_date_signature': avenant.avenant_date_signature,
            'type_monte'            : await ContractConfigTypeMonteTransformer.process(avenant.type_monte, 'light'),
        }
    }

    sortAllLight (array) {
        return array.sortBy('avenant_num', 'ASC')
    }


    async fetchLight (col) {
        return col.with({
            'contract' : 'avenant_contract',
        })
    }

    async transformLight (avenant) {
        return {
            'avenant_id'                : avenant.avenant_id,
            'contract'                  : await ContractTransformer.process(avenant.contract, 'all'),
        }
    }


    async fetchLightWithAvenantId (col) {
        return col.with({
            'contract' : 'avenant_contract',
        })
    }

    async transformLightWithAvenantId (avenant) {
        return {
            'avenant_id'                : avenant.avenant_id,
            'contract'                  : await ContractTransformer.process(avenant.contract, 'onlyAvenantId'),
        }
    }

    async transformUltraLight (avenant) {
        return {
            'avenant_id'                : avenant.avenant_id,
            'avenant_contract'          : avenant.avenant_contract,
            'avenant_num'               : avenant.avenant_num,
            'avenant_horse'             : avenant.avenant_horse,
            'avenant_cmep'             : avenant.avenant_cmep,
            'avenant_status'            : avenant.avenant_status
        }
    }

    async fetchAllWithContract (col) {
        return col.with({
            'horse'     : 'avenant_horse',
            'type'      : 'avenant_type',
            'status'    : 'avenant_status',
            'cmep'      : 'avenant_cmep',
            'type_monte': 'avenant_typemonte',
            'contract' : 'avenant_contract',

        })
    }

    async transformAllWithContract (avenant) {
        return {
            'avenant_id'            : avenant.avenant_id,
            'avenant_num'           : avenant.avenant_num,
            'avenant_justification' : avenant.avenant_justification,
            'avenant_comment'       : avenant.avenant_comment,
            'avenant_horse'         : avenant.avenant_horse,
            'avenant_typemonte'     : avenant.avenant_typemonte,
            'avenant_contract'      : avenant.avenant_contract,
            'avenant_free'          : avenant.avenant_free,
            'avenant_type'          : avenant.avenant_type,
            'avenant_status'        : avenant.avenant_status,
            'avenant_date_signature': avenant.avenant_date_signature,
            'avenant_type_contrat'  : avenant.avenant_type_contrat,
            'avenant_dps'           : avenant.avenant_dps,
            'avenant_date'              : avenant.avenant_date,
            'avenant_date_validation'   : avenant.avenant_date_validation,
            'avenant_transfert'         : avenant.avenant_transfert,
            'horse'                 : await HorseTransformer.process(avenant.horse, 'light'),
            'type'                  : await ContractAvenantTypeTransformer.process(avenant.type),
            'status'                : await ContractAvenantStatusTransformer.process(avenant.status),
            'cmep'                  : await TierTransformer.process(avenant.cmep, 'light'),
            'type_monte'            : await ContractConfigTypeMonteTransformer.process(avenant.type_monte),
            'contract'              : await ContractTransformer.process(avenant.contract, 'withoutAvenant'),
        }
    }
}
