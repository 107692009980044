import Transformer from './Transformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractTypeMonteTransformer extends Transformer {

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transform (contract_typemonte) {
        return {
            'contracttypemonte_id'                 : contract_typemonte.contracttypemonte_id,
            'contracttypemonte_label'              : Vue.i18n.translate(contract_typemonte.contracttypemonte_label),
            'contracttypemonte_type'               : contract_typemonte.contracttypemonte_type,
        }
    }
}
