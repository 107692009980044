import Transformer from './Transformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractAvenantTypeTransformer extends Transformer {

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transform (avenant_type) {
        return {
            'avenanttype_id'            : avenant_type.avenanttype_id,
            'avenanttype_type'          : avenant_type.avenanttype_type,
            'avenanttype_label'         : Vue.i18n.translate(avenant_type.avenanttype_label),
        }
    }
}
