import Transformer from './Transformer'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
import ArticleTransformer from '@/assets/js/dexie/transformers/ArticleTransformer.js'
import ContractConfigConditionsTransformer from '@/assets/js/dexie/transformers/ContractConfigConditionsTransformer.js'
import ContractConditionsTransformer from '@/assets/js/dexie/transformers/ContractConditionsTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractAvenantArticlesTransformer extends Transformer {

    table = 'contract_avenant_articles'

	async fetch (col) {
	    return col.with({
	        'article'                 : 'avenantarticles_article',
            'tiers'                   : 'avenantarticles_tiers',
            'author'                  : 'avenantarticles_author',
            'conditions'              : 'avenantarticles_conditions',
            'conditions_solo'         : 'avenantarticles_type_condition'
	    })
	}


    async transform (avenantarticles) {
        return {
            'avenantarticles_id'                      : avenantarticles.avenantarticles_id,
            'avenantarticles_avenant'                 : avenantarticles.avenantarticles_avenant,
            'avenantarticles_tiers'                   : avenantarticles.avenantarticles_tiers,
            'avenantarticles_ht'         	          : avenantarticles.avenantarticles_ht,
            'avenantarticles_ttc'         	          : avenantarticles.avenantarticles_ttc,
            'avenantarticles_invoiceable'             : avenantarticles.avenantarticles_invoiceable,
            'avenantarticles_invoiceable_date'        : avenantarticles.avenantarticles_invoiceable_date,
            'avenantarticles_author'                  : avenantarticles.avenantarticles_author,
            'avenantarticles_comment'         	      : avenantarticles.avenantarticles_comment,
            'avenantarticles_article'                 : avenantarticles.avenantarticles_article,
            'avenantarticles_type_condition'          : avenantarticles.avenantarticles_type_condition,
            'avenantarticles_qte'                     : avenantarticles.avenantarticles_qte,
            'avenantarticles_htunit'                  : avenantarticles.avenantarticles_htunit,
            'avenantarticles_htunit_before_discount'  : avenantarticles.avenantarticles_htunit_before_discount,
            'avenantarticles_ht_before_discount'      : avenantarticles.avenantarticles_ht_before_discount,
            'avenantarticles_discount_type'           : avenantarticles.avenantarticles_discount_type,
            'avenantarticles_discount_value'          : avenantarticles.avenantarticles_discount_value,
            'avenantarticles_ht_initial'              : avenantarticles.avenantarticles_ht_initial,
            'avenantarticles_quotepart'               : avenantarticles.avenantarticles_quotepart,
            'article'                                 : await ArticleTransformer.process(avenantarticles.article, 'withAccountingPlan'),
            'tiers'                                   : await TierTransformer.process(avenantarticles.tiers, 'light'),
            'author'                                  : await TierTransformer.process(avenantarticles.author, 'light'),
            'conditions'                              : await ContractConfigConditionsTransformer.process(avenantarticles.conditions),
            'conditions_solo'                         : await ContractConditionsTransformer.process(avenantarticles.conditions_solo),
        }
    }
}
