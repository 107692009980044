import Transformer from './Transformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractAvenantStatusTransformer extends Transformer {

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transform (avenant_status) {
        return {
            'avenantstatus_id'            : avenant_status.avenantstatus_id,
            'avenantstatus_type'          : avenant_status.avenantstatus_type,
            'avenantstatus_label'         : Vue.i18n.translate(avenant_status.avenantstatus_label),
        }
    }
}
